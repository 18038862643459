import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Icon } from "@iconify/react";
import "swiper/css";

function SectionPortfolio({ sectionNumber, color }) {
	const [portfolio, setPortfolio] = useState([]);

	useEffect(() => {
		const data = [
			{
				title: "All",
				content: [
					{
						image: require("../assets/img/slides/slide-20.png"),
						subtitle: "Web Development",
					},
					{
						image: require("../assets/img/slides/slide-1.png"),
						subtitle: "UI/UX Design",
					},
					{
						image: require("../assets/img/slides/slide-11.png"),
						subtitle: "Application",
					},
					{
						image: require("../assets/img/slides/slide-17.png"),
						subtitle: "Application",
					},
					{
						image: require("../assets/img/slides/slide-5.png"),
						subtitle: "Web Development",
					},
				],
			},
			{
				title: "App Development",
				content: [
					{
						image: require("../assets/img/slides/slide-11.png"),
						subtitle: "Application",
					},
					{
						image: require("../assets/img/slides/slide-12.png"),
						subtitle: "Application",
					},
					{
						image: require("../assets/img/slides/slide-13.png"),
						subtitle: "Application",
					},
					{
						image: require("../assets/img/slides/slide-17.png"),
						subtitle: "Application",
					},
				],
			},
			{
				title: "Web Development",
				content: [
					{
						image: require("../assets/img/slides/slide-18.png"),
						subtitle: "Web Development",
					},
					{
						image: require("../assets/img/slides/slide-15.png"),
						subtitle: "Web Development",
					},
					{
						image: require("../assets/img/slides/slide-19.png"),
						subtitle: "Web Development",
					},
					{
						image: require("../assets/img/slides/slide-5.png"),
						subtitle: "Web Development",
					},
					{
						image: require("../assets/img/slides/slide-20.png"),
						subtitle: "Web Development",
					},
				],
			},
			{
				title: "UI/UX Design",
				content: [
					{
						image: require("../assets/img/slides/slide-1.png"),
						subtitle: "UI/UX Design",
					},
					{
						image: require("../assets/img/slides/slide-11.png"),
						subtitle: "UI/UX Design",
					},
					{
						image: require("../assets/img/slides/slide-14.png"),
						subtitle: "UI/UX Design",
					},
					{
						image: require("../assets/img/slides/slide-15.png"),
						subtitle: "UI/UX Design",
					},
					{
						image: require("../assets/img/slides/slide-5.png"),
						subtitle: "UI/UX Design",
					},

				],
			},
		];

		setPortfolio(data);
	}, []);

	return (
		<section id="portfolio">
			<div className={`bg-${color} pt-[72px] pb-9 lg:pb-[72px]`}>
				<div className="container">
					<Tabs selectedTabClassName="!border-main !text-main focus:outline-main">
						<div className="flex flex-col lg:flex-row lg:flex-wrap lg:items-center lg:justify-between lg:gap-2">
							<div className="flex items-center gap-2 lg:gap-6">
								<h1 className="section-title__number">{sectionNumber}</h1>
								<h3 className="section-title__text">Our Portfolio</h3>
							</div>

							<TabList className="flex flex-wrap gap-2">
								{portfolio.map((data, index) => (
									<Tab
										key={index}
										className={`cursor-pointer rounded border border-${color} py-3 px-4 font-montserrat text-xs font-semibold uppercase text-grey transition-all duration-200 hover:border-main hover:text-main`}
									>
										{data.title}
									</Tab>
								))}
							</TabList>

							<div className="self-end lg:self-auto">
								<div className="flex gap-6">
									<button className="btn-arrow swiper-left text-white">
										<Icon icon="akar-icons:arrow-left" className="text-lg" />
									</button>
									<button className="btn-arrow swiper-right text-white">
										<Icon icon="akar-icons:arrow-right" className="text-lg" />
									</button>
								</div>
							</div>
						</div>

						{portfolio.map((data, index) => (
							<TabPanel key={index}>
								<Swiper
									className="mt-5"
									breakpoints={{
										768: {
											slidesPerView: 2,
											spaceBetween: 24,
										},
										1024: {
											slidesPerView: 3,
											spaceBetween: 24,
										},
									}}
									modules={[Navigation, Pagination]}
									navigation={{
										prevEl: "#portfolio .swiper-left",
										nextEl: "#portfolio .swiper-right",
										disabledClass: "opacity-50",
									}}
									pagination={{
										clickable: true,
										el: "#portfolio .bullet",
										bulletClass:
											"h-1 w-4 inline-block bg-semilight mx-1 rounded-sm cursor-pointer",
										bulletActiveClass: "!bg-main",
									}}
								>
									{data.content.map((item, index) => (
										<SwiperSlide key={index}>
											<div
												className="group h-80 bg-cover hover:cursor-grab"
												style={{
													backgroundImage: `url(${item.image})`,
													backgroundPosition: "center",
												}}
											>
												<div className="flex h-full w-full flex-col items-center justify-center bg-transparent transition duration-500 group-hover:bg-black group-hover:bg-opacity-60">
													<h3 className="font-anton text-[32px] uppercase text-transparent transition duration-500 group-hover:text-white">
														{item.title}
													</h3>
													<h5 className="font-montserrat font-bold text-transparent transition duration-500 group-hover:text-white">
														{item.subtitle}
													</h5>
												</div>
											</div>
										</SwiperSlide>
									))}

									<div className="bullet mt-8 flex justify-center lg:mt-10" />
								</Swiper>
							</TabPanel>
						))}
					</Tabs>
				</div>
			</div>
		</section>
	);
}

export default SectionPortfolio;
