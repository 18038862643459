import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/PageTitle";
import SectionContact from "../components/SectionContact";
import Footer from "../components/Footer";

function Contact() {
	// const REACT_APP_MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

	return (
		<>
			{/* Navbar */}
			<Navbar />

			{/* Page Title */}
			<PageTitle title="Contact" subtitle="Contact" />

			{/* Section 1 - Contact */}
			<SectionContact
				sectionNumber="01"
				bgColor="transparent"
				textColor="dark"
			/>
			{/* Footer */}
			<Footer />
		</>
	);
}

export default Contact;
