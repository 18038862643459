import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/PageTitle";
import SectionMySkills from "../components/SectionMySkills";
import SectionMyPartners from "../components/SectionMyPartners";
import Footer from "../components/Footer";
import Person from "../assets/img/tampa-skyline.png";
import { Icon } from "@iconify/react";

function About() {
	return (
		<>
			{/* Navbar */}
			<Navbar />

			{/* Page Title */}
			<PageTitle title="About" subtitle="About Us" />

			{/* Section 1 - About Me */}
			<section id="about-me">
				<div className="container pt-20 pb-32">
					<div className="mt-24 grid gap-10 lg:grid-cols-2 lg:gap-0">
						<div className="relative flex h-[400px] justify-center bg-[url(./assets/img/about-us-bg.png)] bg-cover lg:h-full lg:w-2/3">
							<div className="absolute bottom-0">
								<img src={Person} width={485} alt="" />
							</div>
						</div>
						<div>
							<div className="flex items-center gap-2 lg:gap-6">
								<h1 className="section-title__number">01</h1>
								<h3 className="section-title__text">About Us</h3>
							</div>
							<div className="my-6 border border-[#F2F3F4] lg:mt-0" />
							<h2 className="mb-4 font-anton text-2xl lg:text-3xl">
								Founded in 2016
							</h2>
							<p className="mb-4 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							CodeChimps was founded with a clear mission in mind: to solve the significant challenges faced by businesses in the software agency world. Our core focus is on delivering exceptional solutions that prioritize deliverability, quality, and practicality.
							</p>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							In today's fast-paced and ever-evolving technological landscape, businesses require software solutions that not only meet their specific needs but also exceed expectations. We recognized that there was a need for a software agency that could address the critical pain points experienced by organizations, offering comprehensive and reliable services.
							</p>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							Deliverability is at the heart of what we do. We understand the importance of meeting deadlines and ensuring timely delivery of projects. Our dedicated team works diligently to develop streamlined processes and efficient workflows, enabling us to deliver projects on time and within budget. We prioritize open communication with our clients, keeping them informed of project progress every step of the way.
							</p>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							Quality is non-negotiable for us. We take great pride in crafting software solutions that are robust, scalable, and future-proof. Our team of skilled professionals is well-versed in the latest technologies and programming languages, allowing us to design and develop high-quality software programs that meet industry standards and exceed client expectations. Rigorous testing and meticulous attention to detail ensure that our solutions are reliable, secure, and perform flawlessly.
							</p>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							Practicality is key to our approach. We understand that businesses need software solutions that are not only innovative but also practical and user-friendly. Our team takes the time to understand your unique requirements and business processes, tailoring solutions that align with your goals and seamlessly integrate into your existing workflows. We strive to create intuitive interfaces and intuitive user experiences, empowering your team to maximize productivity and efficiency.
							</p>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							At CodeChimps, we are more than just a software agency. We are your trusted partners, dedicated to solving your biggest challenges and driving your success. Our commitment to deliverability, quality, and practicality is the foundation of our company, and we continuously strive to exceed expectations with every project we undertake.
							</p>
							<div className="flex gap-16">
								<div className="flex items-center gap-3">
									<h1 className="font-anton text-[64px] tracking-wider text-main">
										15
									</h1>
									<h3 className="font-montserrat text-sm font-semibold tracking-tighter">
										Years
										<br />
										of Combined Experience
									</h3>
								</div>
								<div className="flex items-center gap-3">
									<h1 className="font-anton text-[64px] tracking-wider text-main">
										100s
									</h1>
									<h3 className="font-montserrat text-sm font-semibold tracking-tighter">
										Of Satisfied
										<br />
										Customers
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Section 2 - My Skills */}
			<SectionMySkills />

			{/* Section 3 - My Experiences */}
			{/* <section id="my-experiences">
				<div className="container">
					<div className="relative">
						<div className="absolute -z-10 grid h-full w-full grid-rows-1 lg:grid-cols-4 lg:divide-x lg:divide-light lg:border-x lg:border-light">
							<div />
							<div />
							<div />
							<div />
						</div>
						<div className="flex items-center justify-center gap-2 pt-28 lg:gap-6">
							<h1 className="section-title__number">03</h1>
							<h3 className="section-title__text">My Experiences</h3>
						</div>
						<div className="grid grid-cols-1 gap-8 pt-12 pb-28 sm:grid-cols-2 lg:grid-cols-4 lg:gap-0">
							<div>
								<Icon
									icon="logos:linkedin"
									className="text-[18px] opacity-40 grayscale"
								/>
								<div className="mt-6 flex items-center gap-4">
									<h3 className="font-anton text-[40px] text-main">01</h3>
									<div>
										<h5 className="font-montserrat font-bold tracking-tight">
											Lead Web Developer
										</h5>
										<h6 className="font-montserrat text-sm font-semibold tracking-tight text-main">
											2016-2020
										</h6>
									</div>
								</div>
								<p className="mt-3 w-4/5 font-montserrat text-sm leading-6 text-grey">
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Delectus quaerat architecto incidunt pariatur.
								</p>
							</div>
							<div className="mt-7 lg:mt-12">
								<Icon
									icon="logos:yahoo"
									className="text-[18px] opacity-40 grayscale"
								/>
								<div className="mt-6 flex items-center gap-4">
									<h3 className="font-anton text-[40px] text-main">02</h3>
									<div>
										<h5 className="font-montserrat font-bold tracking-tight">
											Lead Web Developer
										</h5>
										<h6 className="font-montserrat text-sm font-semibold tracking-tight text-main">
											2016-2020
										</h6>
									</div>
								</div>
								<p className="mt-3 w-4/5 font-montserrat text-sm leading-6 text-grey">
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Delectus quaerat architecto incidunt pariatur.
								</p>
							</div>
							<div className="mt-7 lg:mt-[90px]">
								<Icon
									icon="logos:zendesk"
									className="text-[18px] opacity-40 grayscale"
								/>
								<div className="mt-6 flex items-center gap-4">
									<h3 className="font-anton text-[40px] text-main">03</h3>
									<div>
										<h5 className="font-montserrat font-bold tracking-tight">
											Lead Web Developer
										</h5>
										<h6 className="font-montserrat text-sm font-semibold tracking-tight text-main">
											2016-2020
										</h6>
									</div>
								</div>
								<p className="mt-3 w-4/5 font-montserrat text-sm leading-6 text-grey">
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Delectus quaerat architecto incidunt pariatur.
								</p>
							</div>
							<div className="mt-7 lg:mt-[140px]">
								<Icon
									icon="logos:webflow"
									className="text-[18px] opacity-40 grayscale"
								/>
								<div className="mt-6 flex items-center gap-4">
									<h3 className="font-anton text-[40px] text-main">04</h3>
									<div>
										<h5 className="font-montserrat font-bold tracking-tight">
											Lead Web Developer
										</h5>
										<h6 className="font-montserrat text-sm font-semibold tracking-tight text-main">
											2016-2020
										</h6>
									</div>
								</div>
								<p className="mt-3 w-4/5 font-montserrat text-sm leading-6 text-grey">
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Delectus quaerat architecto incidunt pariatur.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* Section 4 - My Partners */}
			<SectionMyPartners sectionNumber="03" />

			{/* Footer */}
			<Footer />
		</>
	);
}

export default About;
