import React, { useEffect, useState } from "react";
import Accordion from "./Accordion";

const SectionFAQ = ({ sectionNumber }) => {
	const [faqs, setFaqs] = useState([]);

	useEffect(() => {
		const data = [
			{
				question: "What is your approach to ongoing support and maintenance after the software is delivered?",
				answer:
					"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit ab eum autem corrupti dignissimos quae, provident itaque in quibusdam fugit.",
			},
			{
				question: "What services do you offer, and what industries do you specialize in?",
				answer:
					"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae magnam voluptatum autem? Sint veniam labore fuga alias maxime ex. Necessitatibus?",
			},
			{
				question: "How do you handle project management and ensure timely delivery?",
				answer:
					"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi, officiis accusantium, hic nam quod quia, blanditiis soluta debitis tempora eligendi architecto consequuntur voluptatem eos neque ducimus itaque autem totam aliquid repellat sed aspernatur! Labore dolorem mollitia ipsa veniam unde sed ut quo. Id, accusantium sed. Facere ad recusandae aut sunt!",
			},
		];

		setFaqs(data);
	}, []);

	return (
		<section id="faq">
			<div className="lg:container">
				<div className="container bg-extralight py-20 lg:mb-20 lg:px-44">
					<div className="flex items-center justify-center gap-2 lg:gap-6">
						<h1 className="section-title__number">{sectionNumber}</h1>
						<h3 className="section-title__text">Have Any Questions?</h3>
					</div>
					<div className="my-6 border-t border-[#DFE2E5] lg:my-8" />
					<div className="flex flex-col gap-6">
						{faqs.map((item, index) => (
							<Accordion title={item.question} text={item.answer} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SectionFAQ;
