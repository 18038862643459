import React from "react";

const Accordion = ({ title, text }) => {
	return (
		<div className="relative overflow-hidden rounded border bg-white py-6">
			<input
				type="checkbox"
				className="peer absolute inset-x-0 top-6 z-10 h-12 w-full cursor-pointer opacity-0"
			/>
			<div className="flex h-12 w-full items-center px-6">
				<h1 className="font-anton text-xl">{title}</h1>
			</div>
			<div className="absolute top-10 right-7 rotate-90 transition-transform duration-500 peer-checked:-rotate-90">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</div>
			<div className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-40">
				<p className="px-6 pt-2 font-montserrat text-sm leading-8 text-grey">
					{text}
				</p>
			</div>
		</div>
	);
};

export default Accordion;
