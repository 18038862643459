import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Icon } from "@iconify/react";
import "swiper/css";

function SectionTestimonial({ sectionNumber }) {
	const [testimonial, setTestimonial] = useState([]);

	useEffect(() => {
		const data = [
			{
				name: "Mike Chaplinsky",
				role: "YFC SRQ Chapter President",
				image: require("../assets/img/testimonials/yfc.png"),
				quote:
					"codechimps is truly passionate when it comes to his development projects! Would absolutely recommend!",
			},
			{
				name: "Nevin Gordon",
				role: "Founder of YUGO",
				image: require("../assets/img/testimonials/yugo.png"),
				quote:
					"codechimps is truly passionate when it comes to his development projects! Would absolutely recommend!",
			},
		];

		setTestimonial(data);
	}, []);

	return (
		<section id="testimonial">
			<div className="container py-16 lg:py-32">
				<div className="relative">
					<div className="absolute h-full w-full lg:grid lg:grid-cols-3 lg:gap-x-28">
						<div className="grid h-full lg:col-span-2 lg:col-start-2 lg:grid-cols-3 lg:grid-rows-3 lg:gap-y-6">
							<div className="z-10 col-span-2">
								<div className="flex items-center gap-2 lg:gap-6">
									<h1 className="section-title__number">{sectionNumber}</h1>
									<h3 className="section-title__text">Testimonial</h3>
								</div>
							</div>
							<div className="bullet z-10 mb-2 self-end lg:row-start-3" />
							<div className="z-10 place-self-end lg:col-start-3 lg:row-start-3">
								<div className="flex gap-6">
									<button className="btn-arrow swiper-left text-white">
										<Icon icon="akar-icons:arrow-left" className="text-lg" />
									</button>
									<button className="btn-arrow swiper-right text-white">
										<Icon icon="akar-icons:arrow-right" className="text-lg" />
									</button>
								</div>
							</div>
						</div>
					</div>

					<Swiper
						modules={[Navigation, Pagination]}
						navigation={{
							prevEl: "#testimonial .swiper-left",
							nextEl: "#testimonial .swiper-right",
							disabledClass: "opacity-50",
						}}
						pagination={{
							clickable: true,
							el: "#testimonial .bullet",
							bulletClass:
								"h-1 w-4 inline-block bg-semilight mx-1 rounded-sm cursor-pointer",
							bulletActiveClass: "!bg-main",
						}}
					>
						{testimonial.map((item, index) => (
							<SwiperSlide key={index}>
								<div className="mt-28 mb-24 grid lg:my-0 lg:h-[422px] lg:grid-cols-3 lg:gap-x-28">
									<div
										className="h-[386px] bg-cover bg-center lg:h-full"
										style={{ backgroundImage: `url(${item.image})` }}
									/>
									<div className="grid grid-rows-3 gap-y-6 lg:col-span-2 lg:grid-cols-3">
										<div className="row-span-2 row-start-2 lg:col-span-2">
											<Icon icon="fontisto:quote-right" className="text-main" />
											<p className="mt-6 mb-8 font-montserrat text-sm leading-8">
												{item.quote.length > 150
													? `${item.quote.substring(0, 150)}...`
													: item.quote}
											</p>
											<h3 className="mb-1 font-montserrat font-bold text-main">
												{item.name}
											</h3>
											<h3 className="font-montserrat text-sm font-semibold text-grey">
												{item.role}
											</h3>
										</div>
										<div className="self-center lg:col-start-3 lg:ml-auto">
											<h3 className="font-anton text-2xl text-grey">
												<span className="text-main">
													{String(index + 1).padStart(2, "0")}
												</span>
												/{String(testimonial.length).padStart(2, "0")}
											</h3>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
}

export default SectionTestimonial;
