import React from "react";
import { Icon } from "@iconify/react";

const HireMe = () => {
	return (
		<div className="rounded-sm bg-[url(./assets/img/bg-collaboration.png)] bg-cover py-16 px-20 text-white bg-blue">
			<h2 className="text-center font-anton text-[40px]">
				Schedule a Consultation
			</h2>
			<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-center">
				Schedule a free 30-minute consultation with one of our lead developers, we'd love to hear all about your software project!
			</p>
			<div className="mt-10 mb-5 text-center">
				<a className="btn-main font-bold uppercase" href="https://calendly.com/codechimps/30min">Schedule Now</a>
				
			</div>
			<div className="text-center">
			</div>
		</div>
	);
};

export default HireMe;
