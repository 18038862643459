import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({ title, subtitle }) => {
	return (
		<div className="h-[547px] bg-[url(./assets/img/bg-page-title.jpg)] bg-cover">
			<div className="relative flex h-full items-center justify-center overflow-hidden">
				<h1 className="absolute cursor-default whitespace-nowrap font-anton text-[320px] uppercase text-white opacity-[0.02]">
					{subtitle}
				</h1>
				<div className="z-10 text-center">
					<h1 className="font-anton text-[64px] uppercase text-white lg:text-[130px]">
						{title}
					</h1>
					<div>
						<Link
							to="/"
							className="font-montserrat font-bold text-grey transition duration-300 hover:text-main"
						>
							Home
						</Link>
						<span className="font-montserrat font-bold text-white">
							{" "}
							/ {title}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageTitle;
