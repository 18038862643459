import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import Logo from "../assets/img/chimp-icon.png";

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);
	const [height, setHeight] = useState(0);
	const ref = useRef();

	useEffect(() => {
		setHeight(ref.current.clientHeight);
	}, []);

	useEffect(() => {
		window.onscroll = () => {
			window.scrollY === 0 && setIsScrolling(false);
			window.scrollY > height && setIsScrolling(true);
		};
	}, [height]);

	return (
		<nav
			ref={ref}
			className={`fixed z-20 w-full bg-dark transition-all duration-500 ${
				isScrolling ? "lg:bg-dark" : "lg:bg-transparent"
			}`}
		>
			<div 			
				ref={ref}
				className={`phone-num-box fixed z-20 w-full bg-dark transition-all duration-500 ${
					isScrolling ? "lg:bg-dark" : "lg:bg-transparent"
				}`}>
				<p>
					<a href="tel:8139221484" className="text-grey font-montserrat text-xs font-semibold uppercase transition hover:text-main"> <Icon icon="mdi:phone" className="text-[15px] lg:text-[15px]" /> (813)-922-1484</a>
			
				</p>
			</div>
			<div className="container flex flex-col justify-between py-6 lg:flex-row lg:items-center">
				<div
					className={`flex items-center justify-between ${
						isOpen && "border-b border-grey pb-5 lg:pb-0"
					}`}
				>
					<a href="/">
						<img src={Logo} alt="Logo" className="w-12" />
					</a>
					<button
						className="p-2 text-grey lg:hidden"
						onClick={() => setIsOpen(!isOpen)}
					>
						<Icon
							icon="eva:menu-2-fill"
							className={`${isOpen && "hidden"} text-2xl`}
						/>
						<Icon
							icon="eva:close-fill"
							className={`${!isOpen && "hidden"} text-2xl`}
						/>
					</button>
				</div>
				<ul
					className={`${
						isOpen ? "h-48" : "h-0"
					} flex flex-col justify-evenly overflow-hidden transition-all duration-500 ease-out lg:flex lg:h-full lg:flex-row lg:gap-14`}
				>
					<li>
						<NavLink
							to="/"
							end
							className={({ isActive }) =>
								`${
									isActive ? "text-main" : "text-grey"
								} font-montserrat text-xs font-semibold uppercase transition hover:text-main`
							}
						>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/about"
							className={({ isActive }) =>
								`${
									isActive ? "text-main" : "text-grey"
								} font-montserrat text-xs font-semibold uppercase transition hover:text-main`
							}
						>
							About
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/services"
							className={({ isActive }) =>
								`${
									isActive ? "text-main" : "text-grey"
								} font-montserrat text-xs font-semibold uppercase transition hover:text-main`
							}
						>
							Services
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/portfolio"
							className={({ isActive }) =>
								`${
									isActive ? "text-main" : "text-grey"
								} font-montserrat text-xs font-semibold uppercase transition hover:text-main`
							}
						>
							Portfolio
						</NavLink>
					</li>
				</ul>
				<div
					className={`${
						isOpen ? "h-max" : "h-0 overflow-hidden"
					} mb-4 lg:h-full lg:overflow-visible`}
				>
					<NavLink
						to="/contact"
						className={({ isActive }) =>
							`${
								isActive ? "bg-main text-white" : "text-main"
							} rounded-[4px] border border-main py-3 px-4 font-montserrat text-xs font-semibold uppercase transition duration-300 hover:bg-main hover:text-white`
						}
					>
						Contact Us
					</NavLink>
					{/* <a className="text-grey font-montserrat text-xs font-semibold uppercase transition hover:text-main px-10" href="tel:(813)-922-1484">(813)-922-1484</a> */}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
