import React from "react";
import Navbar from "../components/Navbar";
import HireMe from "../components/HireMe";
import SectionMyServices from "../components/SectionMyServices";
import SectionPortfolio from "../components/SectionPortfolio";
import SectionTestimonial from "../components/SectionTestimonial";
import SectionContact from "../components/SectionContact";
import Footer from "../components/Footer";
import Typewriter from "typewriter-effect";
import AboutMe_1 from "../assets/img/dev-about-us.png";
import AboutMe_2 from "../assets/img/aglie-about-us.png";
import BgContact from "../assets/img/bg-contact.jpg";
import { Icon } from "@iconify/react";

function Index() {
	return (
		<>
			{/* Navbar */}
			<Navbar />

			{/* Start Main Section - Hero */}
			<main
				className="h-screen bg-[url(./assets/img/bg-hero.png)] bg-cover bg-center"
				id="hero"
			>
				<div className="container flex h-screen items-center justify-between">
					<div className="text-white">
						<h6 className="font-montserrat text-2xl font-bold leading-[30px]">
							<span className="text-main">We </span>Build, Design & Evolve
						</h6>
						<h1 className="my-8 font-anton text-8xl uppercase md:text-[80px]">
							<Typewriter
								options={{
									strings: ["Apps", "Websites", "E-commerce", "Blockchains", "AI", "Chatbots"],
									autoStart: true,
									loop: true,
									cursor: "",
								}}
							/>
						</h1>
						<h6 className="font-montserrat font-bold leading-[30px] tracking-tighter main-text-box">
						Codechimps delivers tailored software solutions that allow businesses of all sizes thrive in today's digital world.
						</h6>
						<br></br>
						<a className="btn-main mt-6 font-semibold" href="https://calendly.com/codechimps/30min">Schedule a Consultation</a>
					</div>
					<ul className="flex flex-col gap-8">
						<li className="group">
							<a href="https://www.linkedin.com/company/codechimps/">
								<div className="flex h-10 w-10 items-center justify-center rounded-full bg-white bg-opacity-10 text-white transition duration-300 group-hover:bg-main">
									<Icon icon="fa:linkedin" />
								</div>
							</a>
						</li>
						<li className="group">
							<a href="https://dribbble.com/codechimps">
								<div className="flex h-10 w-10 items-center justify-center rounded-full bg-white bg-opacity-10 text-white transition duration-300 group-hover:bg-main">
									<Icon icon="fa:dribbble" />
								</div>
							</a>
						</li>
					</ul>
				</div>
			</main>
			{/* End Main Section - Hero */}

			{/* Start Section 1 - About Me */}
			<section id="about-me">
				<div className="container">
					<div className="relative">
						<div className="absolute -z-10 grid h-full w-full grid-cols-2 grid-rows-1 divide-x divide-light border-x border-light lg:grid-cols-4">
							<div />
							<div />
							<div />
							<div />
						</div>
						<div className="grid grid-cols-1 py-11 lg:grid-cols-4 lg:py-24">
							<div className="flex w-2/3 flex-col items-end justify-self-end text-right lg:w-4/5 lg:justify-self-start">
								<img src={AboutMe_1} alt="About Me" />
								<div className="mt-8 flex items-center gap-3">
									<h3 className="font-montserrat text-sm font-semibold tracking-tighter">
										Satisfied
										<br />
										Customers
									</h3>
									<h1 className="font-anton text-[64px] tracking-wider text-main">
										100s
									</h1>
								</div>
							</div>
							<div className="w-2/3 pb-16 lg:w-4/5 lg:self-end lg:pb-0">
								<div className="mb-7 flex items-center gap-3">
									<h1 className="font-anton text-[64px] tracking-wider text-main">
										10
									</h1>
									<h3 className="font-montserrat text-sm font-semibold tracking-tighter">
										Years
										<br />
										of Experience
									</h3>
								</div>
								<img src={AboutMe_2} alt="About Me" />
							</div>
							<div className="lg:col-span-2 lg:self-center">
								<div className="flex items-start gap-4">
									<h1 className="section-title__number leading-[96px]">01</h1>
									<div>
										<h2 className="section-title__text leading-[96px]">
											Who Are We?
										</h2>
										<p className="mb-4 font-montserrat text-xs font-medium leading-8 tracking-tighter text-grey lg:text-sm lg:leading-8">
										At CodeChimps, we embrace an agile approach that allows us to swiftly adapt and deliver high-quality results. Whether you're looking to build new software from scratch or enhance existing applications. Our team of experienced developers and designers are ready to collaborate with your team on every step!
										</p>
										<p className="mb-4 font-montserrat text-xs font-medium leading-8 tracking-tighter text-grey lg:text-sm lg:leading-8">
										With our deep expertise in software development, we have a proven track record of transforming innovative concepts into successful products. We take the time to understand your vision, business objectives, and target audience, allowing us to craft tailored solutions that resonate with your users and drive growth.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End Section 1 - About Me */}

			{/* Start - Collaboration */}
			<div className="bg-extralight pb-28">
				<div className="container">
					<div className="flex flex-wrap justify-center gap-10 py-20 opacity-40 grayscale lg:gap-20">
						<Icon icon="logos:python" className="text-[28px]" />
						<Icon icon="logos:javascript" className="text-[28px]" />
						<Icon icon="logos:typescript" className="text-[28px]" />
						<Icon icon="logos:php" className="text-[28px]" />
						<Icon icon="logos:mysql" className="text-[28px]" />
					</div>
				</div>
			</div>
			<div className="-mt-28">
				<div className="container">
					<HireMe />
				</div>
			</div>
			{/* End - Collaboration */}

			{/* Section 2 - My Services */}
			<SectionMyServices sectionNumber="02" />

			{/* Section 3 - Portfolio */}
			<SectionPortfolio sectionNumber="03" color="extralight" />

			{/* Section 4 - Testimonial */}
			<SectionTestimonial sectionNumber="04" />

			{/* Section 5 - Contact */}
			<SectionContact
				sectionNumber="05"
				bgColor={`url(${BgContact})`}
				textColor="white"
			/>

			{/* Footer */}
			<Footer />
		</>
	);
}

export default Index;
