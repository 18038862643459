import React from "react";
import { ReactComponent as Call } from "../assets/svg/Call.svg";
import { ReactComponent as Message } from "../assets/svg/Message.svg";
import { ReactComponent as Location } from "../assets/svg/Location.svg";
import { Icon } from "@iconify/react";

function SectionContact({ sectionNumber, bgColor, textColor }) {
	const handleSendMessage = (e) => {
		e.preventDefault();
		alert("Message sent!");
	};

	return (
		<section id="contact">
			<div className="bg-cover" style={{ background: `${bgColor}` }}>
				<div className="container py-16">
					<div className="grid lg:grid-cols-2">
						<div className="flex items-center gap-2 lg:gap-6">
							<h1 className="section-title__number">{sectionNumber}</h1>
							<h3 className={`section-title__text text-${textColor}`}>
								Contact
							</h3>
						</div>
						<div className="flex flex-col gap-10 self-center pt-9 pb-12 lg:row-start-2 lg:p-0">
							<div className="flex items-center">
								<Call className="fill-main" />
								<div className="ml-5">
									<span
										className={`block font-montserrat font-medium tracking-tight text-${textColor}`}
									>
										Phone
									</span>
									<span className="block font-montserrat text-grey">
										(813)-922-1484
									</span>
								</div>
							</div>
							<div className="flex items-center">
								<Message className="fill-main" />
								<div className="ml-5">
									<span
										className={`block font-montserrat font-medium tracking-tight text-${textColor}`}
									>
										Email
									</span>
									<span className="block font-montserrat text-grey">
										info@codechimps.co
									</span>
								</div>
							</div>
							<div className="flex items-center">
								<Location className="fill-main" />
								<div className="ml-5">
									<span
										className={`block font-montserrat font-medium tracking-tight text-${textColor}`}
									>
										Address
									</span>
									<span className="block font-montserrat text-grey">
										5195 S Clarice Ct Suite D, Tampa, FL 33611
									</span>
								</div>
							</div>
							<ul className="flex gap-7">
								<li className="group">
									<a href="https://dribbble.com/codechimps">
										<div
											className={`flex h-10 w-10 items-center justify-center rounded-full bg-${textColor} bg-opacity-10 text-${textColor} transition duration-300 group-hover:bg-main group-hover:text-white`}
										>
											<Icon icon="fa:dribbble" />
										</div>
									</a>
								</li>
								<li className="group">
									<a href="https://www.linkedin.com/company/codechimps/">
										<div
											className={`flex h-10 w-10 items-center justify-center rounded-full bg-${textColor} bg-opacity-10 text-${textColor} transition duration-300 group-hover:bg-main group-hover:text-white`}
										>
											<Icon icon="fa:linkedin" />
										</div>
									</a>
								</li>
							</ul>
						</div>

						<h4 className={`self-center font-anton text-xl text-${textColor} lg:text-2xl`}>
							<span className="text-main"><a href="tel:8139221484">Call us</a> </span>or Have Us Call You!
						</h4>
						<div className="lg:row-start-2">
							<div className="my-6 border border-[#1C1E21] lg:mt-0" />
							<form onSubmit={handleSendMessage}>
								<div className="lg:flex lg:gap-8">
									<div className="mb-8 w-full">
										<label
											htmlFor="name"
											className={`block font-montserrat text-sm font-medium text-${textColor}`}
										>
											Your name<sup>*</sup>
										</label>
										<input
											type="text"
											name="name"
											id="name"
											className={`mt-3 w-full rounded-sm px-4 py-5 font-montserrat text-xs font-medium text-${textColor} placeholder:text-grey focus:outline-none focus:ring-1 focus:ring-main`}
											placeholder="Enter your name here"
											style={{ backgroundColor: "rgba(202,205,209,0.1)" }}
										/>
									</div>
									<div className="mb-8 w-full">
										<label
											htmlFor="email"
											className={`block font-montserrat text-sm font-medium text-${textColor}`}
										>
											Email address<sup>*</sup>
										</label>
										<input
											type="text"
											name="email"
											id="email"
											className={`mt-3 w-full rounded-sm px-4 py-5 font-montserrat text-xs font-medium text-${textColor} placeholder:text-grey focus:outline-none focus:ring-1 focus:ring-main`}
											placeholder="Enter your email address"
											style={{ backgroundColor: "rgba(202,205,209,0.1)" }}
										/>
									</div>
								</div>
								<div className="mb-8">
									<label
										htmlFor="message"
										className={`block font-montserrat text-sm font-medium text-${textColor}`}
									>
										Message<sup>*</sup>
									</label>
									<textarea
										name="message"
										id="message"
										cols="30"
										rows="5"
										className={`mt-3 w-full rounded-sm px-4 py-5 font-montserrat text-xs font-medium text-${textColor} placeholder:text-grey focus:outline-none focus:ring-1 focus:ring-main`}
										placeholder="Write your message here"
										style={{ backgroundColor: "rgba(202,205,209,0.1)" }}
									/>
								</div>
								<button type="submit" className="btn-main font-bold uppercase">
									Send Message
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SectionContact;
