import React, { useEffect, useState } from "react";

function SectionMySkills() {
	const [skills, setSkills] = useState([]);

	useEffect(() => {
		const data = [
			{
				skill: "JavaScript proficiency",
				level: 100,
			},
			{
				skill: "TypeScript proficiency",
				level: 100,
			},
			{
				skill: "Python proficiency",
				level: 100,
			},
			{
				skill: "PHP proficiency",
				level: 100,
			},
			{
				skill: "C++ proficiency",
				level: 100,
			},
			{
				skill: "C# proficiency",
				level: 100,
			},
		];

		setSkills(data);
	}, []);

	return (
		<section id="my-skills">
			<div className="bg-extralight py-20">
				<div className="container">
					<div className="grid gap-12 lg:grid-cols-2 lg:gap-24">
						<div>
							<div className="mb-3 flex items-center gap-2 lg:gap-6">
								<h1 className="section-title__number">02</h1>
								<h3 className="section-title__text">Our stats</h3>
							</div>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							Our team has exceptional proficiency in JavaScript, TypeScript, Python, PHP, C++, and C#, enabling us to deliver high-quality, customized solutions that cater to diverse industry requirements, complex software projects and leverage the full potential of these programming languages to build robust, scalable, and secure software product.
							</p>
							<p className="mb-8 font-montserrat text-xs font-medium leading-8 text-grey lg:text-sm">
							Apart from expertise in programming languages, we excel in utilizing powerful frameworks built upon these languages. These frameworks empower us to leverage advanced functionalities without reinventing the wheel. Some of the top frameworks we work in often times are React, Angular, Laravel, TensorFlow, PyTorch, OpenCV, and more.
							</p>
							<a className="btn-main font-semibold uppercase" href="https://calendly.com/codechimps/30min">
								Schedule a Consultation
							</a>
						</div>
						<div className="self-center">
							<div className="flex flex-col gap-8">
								{skills.map((item, index) => (
									<div key={index}>
										<div className="mb-3 flex justify-between">
											<h5 className="font-montserrat text-sm font-semibold">
												{item.skill}
											</h5>
											<h5 className="font-montserrat text-sm font-semibold">
												{item.level}%
											</h5>
										</div>
										<div className="relative h-[6px] rounded-3xl bg-[#DDDEE0]">
											<div
												className="absolute h-full rounded-3xl bg-main"
												style={{ width: `${item.level}%` }}
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SectionMySkills;
