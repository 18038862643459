import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/PageTitle";
import SectionPortfolio from "../components/SectionPortfolio";
import SectionMyPartners from "../components/SectionMyPartners";
import SectionFAQ from "../components/SectionFAQ";
import Footer from "../components/Footer";

function Portfolio() {
	return (
		<>
			{/* Navbar */}
			<Navbar />

			{/* Page Title */}
			<PageTitle title="Portfolio" subtitle="Portfolio" />

			{/* Section 1 - Portfolio */}
			<SectionPortfolio sectionNumber="01" color="white" />

			{/* Section 2 - My Partners */}
			<SectionMyPartners sectionNumber="02" />

			{/* Section 3 - FAQ */}
			<SectionFAQ sectionNumber="03" />

			{/* Footer */}
			<Footer />
		</>
	);
}

export default Portfolio;
