import React from "react";

const Footer = () => {
	return (
		<div className="h-20 bg-[#0C0C0E]">
			<div className="container flex h-full items-center justify-center text-center">
				<span className="font-montserrat text-sm font-semibold text-grey">
					Copyright <span className="text-white">Codechimps.co</span>{" "}
					2023 All Right Reserved
				</span>
			</div>
		</div>
	);
};

export default Footer;
