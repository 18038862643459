import React from "react";
import HireMe from "./HireMe";
import { Icon } from "@iconify/react";

const SectionMyPartners = ({ sectionNumber }) => {
	return (
		<section id="my-partners">
			<div className="bg-extralight">
				<div className="container pt-16 pb-44">
					<div className="flex items-center justify-center gap-2 lg:gap-6">
						<h1 className="section-title__number">{sectionNumber}</h1>
						<h3 className="section-title__text">Our Partners</h3>
					</div>
					<div className="flex flex-wrap justify-center gap-7 pt-6 pb-14 opacity-40 grayscale lg:gap-x-28 lg:gap-y-14 lg:pt-10">
						<Icon icon="logos:github" className="text-[22px] lg:text-[32px]" />
						<Icon icon="logos:stripe" className="text-[22px] lg:text-[32px]" />
						<Icon
							icon="logos:paypal"
							className="text-[22px] lg:text-[32px]"
						/>
						<Icon icon="logos:twilio" className="text-[22px] lg:text-[32px]" />
						<Icon icon="logos:hubspot" className="text-[22px] lg:text-[32px]" />
						<Icon
							icon="logos:google"
							className="text-[22px] lg:text-[32px]"
						/>
						<Icon icon="logos:udemy" className="text-[22px] lg:text-[32px]" />
						<Icon
							icon="logos:microsoft"
							className="text-[22px] lg:text-[32px]"
						/>
						<Icon icon="logos:mapbox" className="text-[22px] lg:text-[32px]" />
					</div>
				</div>
			</div>
			<div className="-mt-44 mb-16">
				<div className="container">
					<HireMe />
				</div>
			</div>
		</section>
	);
};

export default SectionMyPartners;
