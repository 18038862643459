import React from "react";
import { ReactComponent as WebDevelopment } from "../assets/svg/web-development.svg";
import { ReactComponent as Hosting } from "../assets/svg/hosting.svg";
import { ReactComponent as AppDevelopment } from "../assets/svg/mobile.svg";
import { ReactComponent as ItSupport } from "../assets/svg/it-support.svg";
import { ReactComponent as BlockchainDevelopment } from "../assets/svg/block-chain.svg";
import { ReactComponent as AiDevelopment } from "../assets/svg/ai-development.svg";
import { ReactComponent as UiUx } from "../assets/svg/ui-ux.svg";
import { ReactComponent as Securtiy } from "../assets/svg/security.svg";

const SectionMyServices = ({ sectionNumber }) => {
	return (
		<section id="my-services">
			<div className="container">
				<div className="relative">
					<div className="absolute -z-10 grid h-full w-full grid-rows-1 lg:grid-cols-4 lg:divide-x lg:divide-light lg:border-x lg:border-light">
						<div />
						<div />
						<div />
						<div />
					</div>
					<div className="flex items-center justify-center gap-2 pt-12 lg:gap-6">
						<h1 className="section-title__number">{sectionNumber}</h1>
						<h3 className="section-title__text">Our Services</h3>
					</div>
					<div className="grid grid-cols-1 gap-8 pt-6 pb-20 sm:grid-cols-2 lg:grid-cols-4 lg:gap-0">
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<WebDevelopment className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								Web Development
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								Transform your digital vision into a captivating, high-performance websites that leave a lasting impact.
							</p>
						</div>
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<AppDevelopment className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								App Development
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								Develop a cutting-edge mobile app experience that will captivate users & increase your ROI.
							</p>
						</div>
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<BlockchainDevelopment className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								Blockchain Development
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								Unleash the expediential power of decentralized technology to revolutionize your industry & drive unprecedented growth.
							</p>
						</div>
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<AiDevelopment className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								AI Development
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								Harness the limitless potential of artificial intelligence to unlock transformative insights & drive intelligent automation within your industry.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-8 pt-6 pb-20 sm:grid-cols-2 lg:grid-cols-4 lg:gap-0">
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<UiUx className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								UI/UX Design
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								We'll craft you an intuitive & visually stunning interface that amplifies user engagement & delivers an unforgettable digital experience.
							</p>
						</div>
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<Hosting className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								Hosting
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								We will empower your online presence through lightning-fast, secure, & scalable hosting solutions for seamless performance.
							</p>
						</div>
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<ItSupport className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								IT Support
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								Experience uninterrupted operations with our expert assistance, rapid response, & proactive solutions for your technology needs.
							</p>
						</div>
						<div className="group cursor-pointer bg-extralight py-10 px-5 transition duration-300 hover:bg-main lg:w-5/6">
							<Securtiy className="stroke-main transition duration-300 group-hover:stroke-white" />
							<h4 className="mt-6 mb-2 font-montserrat font-bold tracking-tighter transition duration-300 group-hover:text-white">
								Cyber Security
							</h4>
							<p className="font-montserrat text-sm font-medium leading-6 tracking-tighter text-grey transition duration-300 group-hover:text-white">
								We safeguarding your digital assets with ironclad protection, advanced threat detection, & proactive defense strategies.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SectionMyServices;
