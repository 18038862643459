import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route exac path="/" element={<Home />} />
				<Route exac path="/about" element={<About />} />
				<Route exac path="/services" element={<Services />} />
				<Route exac path="/portfolio" element={<Portfolio />} />
				<Route exac path="/contact" element={<Contact />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
