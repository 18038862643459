import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/PageTitle";
import SectionMyServices from "../components/SectionMyServices";
import SectionMyPartners from "../components/SectionMyPartners";
import SectionTestimonial from "../components/SectionTestimonial";
import FAQ from "../components/SectionFAQ";
import Footer from "../components/Footer";

function Services() {
	return (
		<>
			{/* Navbar */}
			<Navbar />

			{/* Page Title */}
			<PageTitle title="Services" subtitle="My Services" />

			{/* Section 1 - My Services */}
			<SectionMyServices sectionNumber="01" />

			{/* Section 2 - My Partners */}
			<SectionMyPartners sectionNumber="02" />

			{/* Section 3 - Testimonial */}
			<SectionTestimonial sectionNumber="03" />

			{/* Section 4 - FAQ */}
			<FAQ sectionNumber="04" />

			{/* Footer */}
			<Footer />
		</>
	);
}

export default Services;
